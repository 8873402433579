import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Text } from "~/components/common/Typography"
import OutsideLink from "~/components/common/OutsideLink"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 5, mySubpage = 4 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "6.5HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={`As a coach, you can help keep girls healthy and in the game.`}
        image={hero}
        bg={"white"}
        underText={
          <Text
            fontFamily={"AdineuePRORegularItalicWeb"}
            fontSize={["16px", "16px"]}
            lineHeight={["18px", "21px"]}
          >
            This material has been adapted from the{" "}
            <OutsideLink
              link={
                "http://www.childrenshospital.org/centers-and-services/programs/f-_-n/female-athlete-program"
              }
            >
              Boston Children’s Hospital Female Athlete Series
            </OutsideLink>
            <Source>71</Source>
          </Text>
        }
      />

      <Infobox uppercase>
        No one expects you as a coach to function also as a doctor, physical
        therapist, psychologist, or nutritionist. But it would be unrealistic to
        suggest that health issues will not come up for your athletes while they
        are on your team. When these issues come up, your primary responsibility
        is to refer your athletes to a medical professional. We are spending the
        time to look at issues that may arise because you are on the front line
        and may have a closer relationship with girls than other adults in their
        lives.
      </Infobox>

      <InfoboxDetailed
        headerText={
          "Girls may experience different challenges around puberty, and it is all new to them."
        }
        headerMaxWidth={"desktop"}
      >
        <Box maxWidth={"mediumContent"} mx={"auto"} textAlign={"center"}>
          <Text>
            They also may be more likely to endure shoulder injuries, ACL tears,
            concussions, and eating disorders. You are positioned to notice
            things as they are happening. Some of the specific health issues
            that your female athletes may experience include the following:
          </Text>
          <Text uppercase fontFamily={"AdineuePROBoldWeb"} mb={0}>
            The female athlete triad:
          </Text>
          <Text mt={0}>
            This condition describes the interrelationship between bone health,
            menstrual function, and proper nutrition. Eating enough calories for
            healthy growth and development, as well as fueling their sports
            activities is essential. Coaches, consider inviting a nutritionist
            to meet with your athletes to talk about optimal nutrition specific
            to your sport and level of competition.
          </Text>

          <Text uppercase fontFamily={"AdineuePROBoldWeb"} mb={0}>
            ACL injuries:
          </Text>
          <Text mt={0}>
            ACL tears occur five to eight times more frequently in female
            athletes due to anatomical and muscular differences and body
            mechanics. As a coach, consider incorporating functional strength
            training and neuromuscular training with plyometrics and agility
            drills.
          </Text>

          <Text uppercase fontFamily={"AdineuePROBoldWeb"} mb={0}>
            Concussion:
          </Text>
          <Text mt={0}>
            Some studies suggest that girls are at a higher risk of concussion
            than boys. “Among youth basketball players, for example, 11.5% of
            girls seen in the ER are diagnosed with concussions, compared with
            7.2% of boys. Among soccer players, it's 17.1% of girls compared
            with 12.4% of boys.”<Source>72</Source>. While research is limited,
            theories explaining this increased susceptibility include girls
            having smaller mass of the head and neck and less developed neck
            muscles. The data that girls may also have more severe concussion
            symptoms that take longer to resolve could be due to girls reporting
            their injuries more readily. Coaches, pay attention to subtle
            symptoms, and ensure your athletes are wearing appropriate
            equipment.
          </Text>
          <Text>
            What and how you as a coach communicate about pain or injury is also
            critical to your athletes’ well-being. Encourage your athletes to
            trust their bodies and communicate with you about pain and
            discomfort that they are experiencing. If you have taken the steps
            described earlier to develop trust and relationships with the girls
            on your team, it is likely they will feel more willing to let you
            know how they feel and what is going on with their bodies. Be
            careful not to explicitly or inadvertently promote a culture of
            “pushing through the pain.” Remember, you want girls to stay in
            sports, and an injury could sideline them indefinitely.
          </Text>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Menstrual cycle and sports"}
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        pt={6}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"} maxWidth={"mediumContent"} mx={"auto"}>
            <Text>
              In an interview with The Telegraph, USWNT’s fitness coach Dawn
              Scott said, “One emerging issue in women’s sport is the menstrual
              cycle and its impact on performance, player health and injury
              risk.”
              <Source>73</Source>
            </Text>
            <Text>
              In 2019, Dawn Scott led an initiative to end period taboo for the
              USA World Cup team. Her partner, Dr. George Bruinvels, stated to
              The Telegraph, “There’s no evidence that someone can’t perform to
              their best at any time in their cycle – if they are proactive
              about taking steps.”<Source>74</Source> Normal, healthy menstrual
              cycles should not get in the way of competing, and research shows
              that performance stays about the same during menstruation.
            </Text>
            <Text>
              Menstrual cycles can however provide information about overall
              health, including whether or not girls are getting proper
              nutrition or training too hard. Issues with menstruation indicate
              hormonal disruptions, which can impact bone health and density.
              Healthy estrogen and other hormone levels help build bones and
              keep them from breaking down.
            </Text>
            <Text>
              Research from the Boston Children's Hospital’s Female Athlete
              program states that “an amenorrheic athlete is two to four times
              more at risk for a stress fracture than an athlete who has regular
              periods.”<Source>75</Source>
            </Text>
            <Text>
              Research also states that exercising during menstruation can
              soothe cramps and PMS symptoms.<Source>76</Source>
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
              mb={0}
            >
              Social issues related to menstruation
            </Text>
            <Text>
              Girls may have all kinds of issues around menstruation, and it is
              all new to them. You as a coach may be the adult in their lives
              best positioned to notice things as they are happening, and you
              could significantly help a girl by addressing them with her and
              her parents or guardians.
            </Text>
            <Text>
              There are also ways that you as a coach can be sensitive and
              thoughtful about this stage of adolescent development. For
              example, you could talk generally about where supplies are kept
              for girls who may need them during practice. You can choose
              uniforms that are darker colored to avoid anxiety about leakage.
              You can address nutritional needs of female athletes who are at
              risk for iron depletion. Normalizing discussion of the menstrual
              cycle rather than avoiding the topic will increase their comfort
              around it.
            </Text>
            <Text>
              “For the 15-year-old girl who doesn’t have the support of a
              national team, I want to make it so she can talk about it with a
              female coach and a male coach...We need to make people aware of it
              and not embarrassed by it.” - Dawn Scott<Source>77</Source>
            </Text>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 5, 4)
